.v-signup-imgsec {
    width: 48%;
    background: #FAFBFC;
    padding: 1rem 2rem 3rem;
    display: flex;
    flex-direction: column;
}
.v-logo {
    width: 60px;
    display: block;
    cursor: pointer;
}
.v-signup-img {
    margin: 8rem auto;
    display: block;
    width: 85%;
    margin: auto auto 0;
}
.v-signup-imgsec-txt {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    max-width: 400px;
    margin: 2rem auto;
}
.txtMargsmall {
    margin-bottom: 0;
}
.txtMarglarge {
    margin-bottom: 8rem;
}
.v-forgot-width {
    width: 75%;
}

@media (max-width: 800px) {
    .v-signup-imgsec {
        display: none;
    }
}
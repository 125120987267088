.v-verifymailexample {
  color: #5b7fff;
}
.v-verify-body {
  margin-top: 35%;
}
.container {
  display: flex;
}
.info {
  width: 52%;
  padding: 1rem 8% 4rem;
}
.info-content {
  margin-top: 3rem;
}
.info-ttl {
  color: #323b4b;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.info-txt {
  font-size: 14px;
  margin-bottom: 1.2rem;
}
.form {
  margin-top: 1.8rem;
}
.mobile-hd img {
  display: none;
}
.mobile-hd {
  margin-bottom: 3rem;
}
.form-btn {
  display: block;
  width: 100%;
  border: none;
  height: 48px;
  border-radius: 10px;
  margin: 2rem auto;
}
.form-btn-inactive {
  background: #f3f3f3;
  color: #b0b7c3;
  cursor: not-allowed;
}
.form-btn-active {
  background: #5b7fff;
  color: #fff;
  cursor: pointer;
}
.form-txt {
  font-size: 14px;
}
.form-txt a,
.form-label > span > span {
  color: #5b7fff;
  text-decoration: none;
  cursor: pointer;
  padding: 0 3px;
  font-weight: 600;
}
.info-content {
    margin: auto;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* min-height: 550px; */
  }
@media (max-width: 800px) {
  .info {
    width: 100%;
  }
  .mobile-hd {
    align-items: center;
    display: flex;
  }
  .mobile-hd img {
    width: 50px;
    display: block;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .info {
    text-align: center;
    padding-top: 2rem;
  }
  .info-txt {
    margin-top: 0px;
    margin-bottom: 2rem;
  }
}

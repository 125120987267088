.v-dash-nav-logout-wrap [class*="modal-dialog"] {
  max-width: 225px;
  width: 225px;
  position: absolute;
  right: 8%;
  top: 6rem;
}
.v-dash-nav-logout-wrap [class*="modal-content"] {
  background: #fff;
  border: 1px solid #ff5630;
}
.v-dash-nav-logout {
  color: #ff5630;
  padding: 1rem 2rem !important;
  border-radius: 10px;
  height: 65px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.v-dash-nav-logout img {
  margin-right: 1rem;
}

.container {
  padding: 3% 8%;
}
.name {
  font-weight: 600;
  font-size: 20px;
  color: #4e5d78;
  margin-bottom: 8px;
  text-transform: capitalize;
}
.name span {
  font-weight: normal;
}
.subtitle {
  font-size: 14px;
  color: #8a94a6;
}
.rule {
  border: 0;
  border-bottom: 1.5px solid #f3f3f3;
  margin: 46px 0;
}
.box {
  background: #fafbfc;
  border-radius: 24px;
  padding: 30px 3% 50px;
  margin-bottom: 54px;
}
.title {
  font-weight: bold;
  font-size: 18px;
  color: #4e5d78;
  margin-bottom: 29px;
}
.cardBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card {
  background: #ffffff;
  box-shadow: 0px 12px 16px rgba(17, 17, 17, 0.02);
  border-radius: 12px;
  height: 100%;
  width: 24%;
  padding: 19px 2%;
  margin-bottom: 2rem;
}
.cardIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 14px;
}
.cardText {
  font-weight: 500;
  font-size: 16px;
  color: #8a94a6;
  margin-bottom: 0;
}
.cardNum {
  margin-top: 15px;
  margin-bottom: 24px;
}
.admissionsNum,
.applicationsNum,
.partnershipsNum {
  font-size: 40px;
  font-weight: 800;
  word-wrap: break-word;
}
.applicationsNum {
  color: #38cb89;
}
.admissionsNum {
  color: #5b7fff;
}
.partnershipsNum {
  color: #4b369d;
}
.enquires {
  color: #ffab00;
  font-weight: bold;
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.recentBox {
  background: #fafbfc;
  border-radius: 24px;
  padding: 33px 2% 56px;
}
.recentTitle {
  color: #1f0c6a;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 27px;
  position: relative;
}
.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.referralBtn {
  font-size: 14px;
  padding: 8px 2rem;
  border-radius: 8px;
}
.tableStructure {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1.5rem;
  border-bottom: 1px solid #f3f3f3;
}
.mobileTtl {
  display: none;
}
.tableHead {
  color: #4e5d78;
  font-weight: 600;
  background: #fafbfc;
  padding: 1.5rem;
  border-radius: 10px;
  border: none;
  margin-bottom: 1rem;
}
.tableBody {
  color: #323b4b;
}
.bodySectionOne {
  display: flex;
  align-items: center;
}
.bodySectionOne > div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}
.bodySectionOne > div > img {
  width: 100%;
  border-radius: 100%;
  height: 100%;
}
.list {
  background: #fff;
  border-radius: 12px;
  padding: 1rem;
}
.emptyState {
  text-align: center;
}
.emptyState img {
  width: 90%;
  max-width: 300px;
}
.emptyState p {
  margin: 2rem 0 1rem;
  color: #1f0c6a;
  font-weight: 500;
}
@media screen and (max-width: 1100px) {
  .container {
    padding: 3% 5%;
  }
  .tableStructure {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }
  .card {
    width: 30%;
  }
}

@media (max-width: 800px) {
  .container {
    padding: 5%;
  }
  .name {
    font-size: 16px;
  }
  .subtitle {
    font-size: 12px;
  }
  .box {
    padding: 22px 3% 33px;
    margin-bottom: 67px;
  }
  .title {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .cardBox {
    flex-wrap: wrap;
  }
  .card {
    width: 47%;
  }
  .card:nth-child(-n + 2) {
    margin-bottom: 20px;
  }
  .cardIcon {
    width: 36px;
    height: 36px;
    padding: 10px;
  }
  .cardText {
    font-size: 14px;
  }
  .cardNum {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .admissionsNum,
  .applicationsNum,
  .partnershipsNum {
    font-size: 20px;
  }
  .enquires {
    font-size: 12px;
    margin-top: 14px;
    margin-bottom: 17px;
  }
}
@media screen and (max-width: 600px) {
  .mobileTtl {
    display: inline;
    margin-right: 2rem;
    color: #4e5d78;
    font-weight: 600;
  }
  .tableStructure {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
  .tableHead {
    display: none;
  }
  .tableBody > div {
    padding: 1rem 0;
  }
  .tableBody {
    margin-bottom: 2rem;
    background: #fff;
    border: none;
    border-radius: 8px;
    padding: 1rem;
  }
  .bodySectionOne {
    justify-content: center;
  }
  .referralBtn {
    padding: 6px 1.5rem;
    font-size: 13px;
    margin-top: 12px;
  }
  .header {
    flex-direction: column;
  }
  .rule {
    margin: 2rem 0;
  }
  .card {
    padding-left: 1rem;
  }
}

.success {
  width: 90%;
  margin: auto;
  padding: 1.5rem 0 4rem;
  text-align: center;
}
.success-logo {
  display: none;
}
.success-ttl {
  /* text-transform: uppercase; */
  text-align: center;
  padding-top: 3rem;
}
.success-img {
  width: 90%;
  max-width: 250px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: block;
}
.success-txt {
  font-weight: 600;
  font-size: 16px;
  margin: 1rem 0 3rem;
  text-align: center;
}
.success-link {
  background: #5b7fff;
  color: #fff !important;
  border: 1px solid #5b7fff;
  border-radius: 10px;
  padding: 8px 2rem;
  text-align: center;
  height: 50px;
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.success-link:hover {
  transform: scale(1.1);
}
.success-link:disabled {
  cursor: not-allowed;
}
.success-link:disabled:hover {
  transform: scale(1);
}
.noverify {
  min-height: 100vh;
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 2rem;

  color: #323b4b;
}
.noverify button {
  font-size: 16px;
  margin-top: 3rem;
  font-weight: 400;
}
.noverify img {
  margin-bottom: 3rem;
  width: 55px;
}
.noverify-input {
  font-size: 14px;
  margin: 2rem auto 0;
  height: 50px;
  padding-left: 1rem;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  width: 90%;
  max-width: 450px;
}
[disabled="true"] {
  opacity: 0.8;
  cursor: not-allowed;
}
.container {
  display: flex;
}
.info {
  width: 52%;
  padding: 1rem 8% 4rem;
}
.info-content {
  margin-top: 3rem;
}
.info-ttl {
  color: #323b4b;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.info-txt {
  font-size: 14px;
  margin-bottom: 1.2rem;
}
.info-content {
  text-align: center;
  margin-top: 2rem;
}
.info-content h1 {
  color: #323b4b;
  font-weight: 600;
}
@media (max-width: 800px) {
  .info {
    width: 100%;
  }
  .success-logo {
    width: 60px;
    display: block;
  }
  .success-ttl {
    padding-top: 4rem;
  }
  .noverify {
    font-size: 1.6rem;
  }
}

@media (max-width: 600px) {
  .info {
    text-align: center;
    padding-top: 2rem;
  }
  .info-txt {
    margin-top: 0px;
    margin-bottom: 2rem;
  }
}

.container {
  display: flex;
}
.info-content {
  margin: auto;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 550px; */
}
.forgot {
  margin-left: auto;
  float: right;
  font-size: 13px;
  text-decoration: none;
  color: #5b7fff;
  cursor: pointer;
  font-weight: 500;
}

.input[type="checkbox"] {
  display: none;
}

.info {
  width: 52%;
  padding: 1rem 8% 4rem;
}

.info-ttl {
  color: #323b4b;
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.info-txt {
  font-size: 14px;
  margin-bottom: 1.2rem;
}
.mobile-hd {
  margin-bottom: 3rem;
}
.mobile-hd img {
  display: none;
}
.form-wrapinput {
  position: relative;
}
.form-wrapinput img {
  position: absolute;
  left: 14px;
  top: 18px;
  width: 17px;
  z-index: 1;
}
.form-input {
  display: block;
  width: 100%;
  border-radius: 10px;
  height: 55px;
  border: 1px solid #f3f3f3;
  outline: none !important;
  margin-bottom: 1.4rem;
  color: inherit;
  padding-left: 3rem;
  font-family: inherit;
}
.form-select {
  padding: 0;
  text-indent: 3rem;
}
.form-input:focus {
  border: 1px solid #38cb89;
}
.form-input-private {
  width: 49%;
  display: inline-block;
  margin-right: 2%;
}
.form-input [class*="signupSelect__control"] {
  height: 100%;
  border-width: 0;
  border-radius: inherit;
  background: transparent;
  padding: 0;
}
.form-input:focus [class*="signupSelect__control"] {
  border: 1px solid #38cb89;
}
.form-input:hover [class*="signupSelect__control"] {
  border-color: #38cb89;
}
.form-input [class*="signupSelect__control--is-focused"] {
  border: inherit;
  box-shadow: none;
  border: 1px solid #38cb89;
}
.form-input [class*="signupSelect__indicator-separator"] {
  display: none;
}

.form-input [class*="signupSelect__placeholder"] {
  color: #b0b7c3;
}

.form-input [class*="signupSelect__value-container"] {
  height: 100%;
}
.form-input [class*="signupSelect__menu"] {
  z-index: 2;
}
.empty {
  border-color: #ff5630;
}
.form-label {
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: left;
}

.form-label::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  aspect-ratio: 1;
  background: #f3f3f3;
  border-radius: 8px;
  margin-right: 1.4rem;
  cursor: pointer;
}
.form-label-red::before {
  outline: 1px solid red;
}
.blueTick {
  position: absolute;
  cursor: pointer;
}
.form-btn {
  display: block;
  width: 100%;
  border: none;
  height: 48px;
  border-radius: 10px;
  margin: 2rem auto;
}
.form-btn-inactive {
  background: #f3f3f3;
  color: #b0b7c3;
  cursor: not-allowed;
}
.form-btn-active {
  background: #5b7fff;
  color: #fff;
  cursor: pointer;
}
.form-txt {
  font-size: 14px;
}
.form-txt a,
.form-label > span > span {
  color: #5b7fff;
  text-decoration: none;
  cursor: pointer;
  padding: 0 3px;
  font-weight: 600;
}
.v-show-pwd {
  right: 14px;
  left: unset !important;
}
.v-pwd-padd {
  padding-right: 2.5rem;
}
@media (max-width: 800px) {
  .info {
    width: 100%;
  }
  .mobile-hd {
    align-items: center;
    display: flex;
  }
  .mobile-hd img {
    width: 50px;
    display: block;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .form-label::before {
    width: 25px;
    height: 25px;
    margin-right: 1rem;
  }
  .blueTick {
    width: 25px;
    height: 25px;
  }
}

.wrap {
  display: flex;
  padding: 1.2rem 8%;
  justify-content: space-between;
  align-items: center;
}
.wrap img {
  width: 52px;
}
.wrap button {
  font-size: 14px;
  padding: 8px 2rem;
  border-radius: 8px;
}

@media screen and (max-width: 600px) {
  .wrap img {
    width: 40px;
  }
  .wrap button {
    padding: 6px 1.5rem;
    font-size: 13px;
  }
  .wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.form {
    margin-top: 1.5rem;
}
.v-pwd-padd {
    padding-right: 3.5rem;
}
.v-show-pwd {
    right: 2rem;
    left: auto !important;
    top: 1.3rem !important;
    width: 18px !important;
    cursor: pointer;
}

@media(max-width: 800px) {
    .v-show-pwd {
        right: 12px;
    }
    .v-pwd-padd {
        padding-right: 2rem;
    }
}
@media(max-width: 400px) {
    .v-show-pwd {
        display: none;
    }
    .v-pwd-padd {
        padding-right: 5px;
    }
}

.link {
    width: max-content;
    justify-content: flex-start;
}
.link img {
    margin-right: 10px;
}

  
  .form-input {
    display: block;
    width: 100%;
    border-radius: 10px;
    height: 55px;
    border: 1px solid #f3f3f3;
    outline: none !important;
    margin-bottom: 1.4rem;
    color: inherit;
    padding-left: 3rem;
    font-family: inherit;
  }
  .form-select {
    padding: 0;
    text-indent: 3rem;
  }
  .form-input:focus {
    border: 1px solid #38cb89;
  }
  .form-input-private {
    width: 49%;
    display: inline-block;
    margin-right: 2%;
  }
  
  .container {
    display: flex;
  }
  .info {
    width: 52%;
    padding: 1rem 8% 4rem;
  }
  .info-content {
    margin-top: 3rem;
  }
  .info-ttl {
    color: #323b4b;
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }
  .info-txt {
    font-size: 14px;
    margin-bottom: 1.2rem;
  }
  .form {
    margin-top: 1.8rem;
  }
  .mobile-hd img {
    display: none;
  }
  .mobile-hd {
    margin-bottom: 3rem;
  }
  .form-btn {
    display: block;
    width: 100%;
    border: none;
    height: 48px;
    border-radius: 10px;
    margin: 2rem auto;
  }
  .form-btn-inactive {
    background: #f3f3f3;
    color: #b0b7c3;
    cursor: not-allowed;
  }
  .form-btn-active {
    background: #5b7fff;
    color: #fff;
    cursor: pointer;
  }
  .form-txt {
    font-size: 14px;
  }
  .form-txt a,
  .form-label > span > span {
    color: #5b7fff;
    text-decoration: none;
    cursor: pointer;
    padding: 0 3px;
    font-weight: 600;
  }
  .form-wrapinput {
    position: relative;
  }
  .form-wrapinput img {
    position: absolute;
    left: 14px;
    top: 18px;
    width: 17px;
    z-index: 1;
  }
  .form-img {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    width: 95px;
    height: 95px;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    color: #b0b7c3;
    margin-bottom: 1.5rem;
  }
  .form-img img {
    margin-bottom: 7px;
    width: 20px;
  }
  .empty {
    border-color: #ff5630;
  }
  .no-mail {
    font-weight: 600;
    margin-top: -0.7rem;
    color: #ff5630;
    font-size: 14px;
    margin-bottom: 1.55rem;
  }
  .info-content {
      margin: auto;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* min-height: 550px; */
    }
  @media (max-width: 800px) {
    .info {
      width: 100%;
    }
    .mobile-hd {
      align-items: center;
      display: flex;
    }
    .mobile-hd img {
      width: 50px;
      display: block;
      cursor: pointer;
    }
  }
  
  @media (max-width: 600px) {
    .info {
      text-align: center;
      padding-top: 2rem;
    }
    .info-txt {
      margin-top: 0px;
      margin-bottom: 2rem;
    }
  }
  
  @media (max-width: 600px) {
    .v-forgot-wrap {
      margin: 0.25rem 0 2.5rem;
    }
  }
  